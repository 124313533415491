import { Store } from "./src/redux/store";
import ReactDOM from "react-dom/client";

function initGTMOnEvent(event) {
  initGTM();
  event.currentTarget.removeEventListener(event.type, initGTMOnEvent, { passive: true }); // remove the event listener that got triggered
}

function initGTM() {
  if (window.gtmDidInit) {
    return false;
  }

  window.gtmDidInit = true; // flag to ensure script does not get added to DOM more than once.

  // Check if dataLayer is defined, if not initialize it
  window.dataLayer = window.dataLayer || [];

  var script = document.createElement("script");

  script.type = "text/javascript";
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtm.js?id=GTM-N37SMCX`;

  script.onload = function () {
    window.dataLayer.push({
      // ensure PageViews are always tracked
      event: "gtm.js",
      "gtm.start": new Date().getTime(),
      "gtm.uniqueEventId": 0,
    });
  };

  document.head.appendChild(script);
}

export const onClientEntry = function () {
  document.onreadystatechange = function () {
    if (document.readyState !== "loading") setTimeout(initGTM, 5000);
  };

  // Add passive: true to the event listeners
  document.addEventListener("scroll", initGTMOnEvent, { passive: true });
  document.addEventListener("mousemove", initGTMOnEvent, { passive: true });
  document.addEventListener("touchstart", initGTMOnEvent, { passive: true });
};

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};

export const wrapRootElement = Store;
